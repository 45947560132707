@import '../variables';

.print {
    visibility: hidden;
}

@media print {
    .print {
        margin: 0;
        padding:0 0 200px 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        font-size: 12px;
        font-family: Verdana, Helvetica, sans-serif;
        color: #000;
        line-height: 15px;
        overflow-wrap: break-word;
    }

    .print__logo img{
        max-width: 100%;
        width: 100%;
    }

    .print__netlink-logo img{
        max-width: 100%;
    }

    .print__customer {
        margin: 5px 0 20px 0;
    }

    .print * {
        overflow-wrap: break-word;
        visibility: visible;
    }
}
