.icon {
  width: 32px;
  height: 32px;

  svg {
    color: inherit;
    fill: currentColor;
    stroke: currentColor;
    width: inherit;
    height: inherit;
  }

  &__button {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: all 0.3s ease-in-out;

    &--danger {
      color: $danger;

      &:hover {
        color: $danger-hover;
      }
    }

    .icon {
      margin-right: 17px;
    }
  }

  &--13 {
    width: 13px;
    height: 13px;
  }

  &--small {
    width: 16px;
    height: 16px;
  }

  &--20 {
    width: 20px;
    height: 20px;
  }

  &--18 {
    width: 18px;
    height: 18px;
  }

  &--23 {
    width: 23px;
    height: 23px;
  }

  &--24 {
    width: 24px;
    height: 24px;
  }

  &--38 {
    width: 38px;
    height: 38px;
  }

  &__wrapper {
    &--round {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #d5e1f9;

      &--stat {
        background-color: $brand-color;
      }
    }

    &--30 {
      width: 30px;
      height: 30px;
    }

    &--alert__standby {
      background-color: $emerald;
    }

    &--alert__unread {
      animation-name: color;
      animation-duration: 3s;
      animation-iteration-count: infinite;

      @keyframes color {
        0% {
          background-color: $bluey-grey-58;
        }
        50% {
          background-color: $sun-yellow;
        }
        100% {
          background-color: $bluey-grey-58;
        }
      }
    }

    &--alert__catastrophic {
      animation-name: colour;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;

      @keyframes colour {
        0% {
          background-color: $bluey-grey-58;
        }
        50% {
          background-color: $tomato;
        }
        100% {
          background-color: $bluey-grey-58;
        }
      }
    }

    &--60 {
      width: 60px;
      height: 60px;
    }
  }

  &--white {
    color: white;
  }
  
  &--notification__standby {
    color: #EAF0FC;
  }

  &--bluey-grey {
    color: $bluey-grey;
  }

  &--brownish-grey {
    color: $brownish-grey;
  }

  &--primary {
    color: $brand-color;
  }

  &--danger {
    color: $danger;
  }

  &--fill-primary {
    color: transparent;

    > svg {
      fill: $brand-color;
    }
  }

  &--fill-white {
    color: transparent;

    > svg {
      fill: $white;
    }
  }

  &--fill-danger {
    color: transparent;

    > svg {
      fill: $danger;
    }
  }

  &--box {
    border-radius: 5px;
    background-color: $cloudy-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $brand-color;
    }

    &.selected {
      background-color: $brand-color;
    }

    &.disabled {
      background-color: $cloudy-blue;
      opacity: 0.2;
    }
  }

  &--round {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: $brand-color;
  }
}
