@import '../variables';

html,body {
    height: 100% !important
}

body {
    width: 100%;
    height: 100%;
    background: $body-bg;
    overflow-y: visible;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.height--full {
    position: absolute;
    width: 100%;
    height: 100%;
}

.height--full--scroll {
    overflow-y: auto;
    position: absolute;
    width: 100%;
    height: 100%;
}



* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #a29f99;
}
::-moz-placeholder { /* Firefox 19+ */
    color: #a29f99;
}
:-ms-input-placeholder { /* IE 10+ */
    color: #a29f99;
}
:-moz-placeholder { /* Firefox 18- */
    color: #a29f99;
}


// hide everything when printing
@media print {
    body * {
        visibility: hidden;
    }
}
