@import '../variables';

.reports {
    margin-top: -20px;
    &__list {
        margin-left: -15px;

        .list-group-item {
            cursor: pointer;
        }

    }

    &__toolbar {
        display: flex;
        flex-direction: row;
    }

    &__content {
        margin-top: 15px;
        padding: 0 20px ;
    }

    &__lines {
        padding: 20px 10px;
    }

    &__line {
        padding: 5px 10px;
    }

    &__line-row {
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        width: 150px;
        justify-content: space-between;
    }

    &__title {
        @extend .text--large;
        padding: 5px 10px;
    }

    &__provider {
        padding: 5px 10px;
    }

    &__station {
        padding: 5px 10px;

        span {margin-right: 5px;}
    }

    &__user {
        padding: 5px 10px;
        span {margin-right: 5px;}
    }

    &__date {
        padding: 5px 10px;
        span {margin-right: 5px;}
    }

    &__print {
        margin-top: 20px;
    }

}

.report {
    &__lines {
        max-height: 243px;
        overflow: auto;
    }
}


@media print {
    .reports__data {
        background-color: white;
        margin: 0;
        padding:0 0 200px 0;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        font-size: 12px;
        font-family: Verdana, Helvetica, sans-serif;
        color: #000;
        line-height: 15px;
        overflow-wrap: break-word;
        height: 100%;


        .table__header {
            display: none;
            height: 0;
            padding: 0;
        }

        .table__row {
            padding: 0 5px;
            background: transparent;
        }

        .table__body {
            box-shadow: none;
            background: transparent;
            padding: 0;
        }

        .table__footer {
            padding: 0 5px;
            background: transparent;
            box-shadow: none;
            border-top: 1px dashed #000;
        }

        .reports__lines {
            padding: 5px;
        }
    }

    .reports__title {
        border-bottom: 1px dashed #000;
    }

    .reports__data * {
        overflow-wrap: break-word;
        visibility: visible;

    }

}
