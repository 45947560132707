@import '../variables';

.numpad {
  padding: 7px 7px;

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 5px;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__btn {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    background-color: $white;
    margin-right: 5px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;

    @media screen and (max-height: 700px) {
      height: 8.35vh;
    }

    &:last-child {
      margin-right: 0;
    }

    .icon {
      margin-right: 12px;
    }

    &--success {
      background-color: $shamrock-green;
      color: white;
    }

    &--primary {
      background-color: $brand-color;
      color: white;
    }

    &--gray {
      background-color: $bluey-grey;
      color: white;
    }

    &--double {
      flex: 2;
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
