.form {
  &__input {
    max-width: 422px;
    margin: auto;

    &--barcode {
      position: relative;
      max-width: 100%;

      .icon {
        position: absolute;
        right: 8px;
        top: 8px;
        color: $bluey-grey;
      }
    }
  }

  &__row {
    margin-bottom: 20px;

    &--split {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &--sidebar {
      margin-bottom: 10px;
    }

    .form-label {
      @extend .text--small;
      display: block;
      margin-bottom: 5px;
    }
  }

  &__label {
    margin-bottom: 0;

    &--sidebar {
      @extend .text--small;
      margin-bottom: 0.3rem;
      display: block;
    }
  }

  &__error {
    margin-top: 0.3rem;
    @extend .text--small, .text--danger;
  }

  &__switchWrapper {
    margin-bottom: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__weightWrapper {
    margin-bottom: 30px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__switch {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__switchLabel {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__switchControl {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 5px;
    }
  }
}
