/*ToDO: Make this a styled component*/
.loader {
  z-index: 9999;
}

.loader__wrapper {
  margin: 50px 0;
}
.loader--area {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  background: rgba(255, 255, 255, 0.9);
  transition: opacity 0.5s ease-in-out;
  z-index: 10;
}
.loader--content {
  position: absolute;
  left: 0;
  right: 0;
  top: 55px;
  bottom: 0;
  opacity: 0;
  background: #fff;
  transition: opacity 0.5s ease-in-out;
  z-index: 100;
}

.loader--spinner {
  position: absolute;
  left: 50%;
  margin-left: -14px;
  top: 50%;
  margin-top: -9px;
  border: 4px solid #FFF;
  border-top-color: transparent;
  border-left-color: transparent;
  width: 20px;
  height: 20px;
  opacity: 0.8;
  border-radius: 50%;
  animation: loadingSpinner 0.7s infinite linear;
  -webkit-animation: loadingSpinner 0.7s infinite linear;
}

.loader--spinner-large {
  margin-top: -20px;
  margin-left: -20px;
  width: 32px;
  height: 32px;
}

.loader--spinner-blue {
  border: 4px solid #333;
  border-top-color: transparent;
  border-left-color: transparent;
}
.loader--spinner-okto {
  border: 5px solid #4c84ff;
  border-top-color: transparent;
  border-left-color: transparent;
  width: 55px;
  height: 55px;
}
.loader__container {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -35px;
  margin-left: -35px;
}
.loader--visible {
  visibility: visible;
  opacity: 1;
}
.loader--hidden {
  opacity: 0;
  pointer-events: none;
}
.loader__double-bounce1, .loader__double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #e78f53;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2.0s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
}
.loader__double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1s;
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
