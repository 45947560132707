.label-selection {
    border: 2px dotted $brand-color;
    
    border-radius: 10px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    
    margin-bottom: 30px;
    
    &__active{
        border: 2px dotted $brand-color;
    }

    &__inactive{
        border: 2px solid gray;
        
    }


       
}
