@import "../variables";

.margin {
    position: relative;

    &--t10 {
        display: inline-block;
        margin-top: 10px;
    }

    &--t20 {
        display: inline-block;
        margin-top: 20px;
    }


    &--t30 {
        display: inline-block;
        margin-top: 30px;
    }

    &--b10 {
        display: inline-block;
        margin-bottom: 10px;
    }

    &--b30 {
        display: inline-block;
        margin-bottom: 30px;
    }

    &--50 {
        margin: 50px;
    }

    &--r10 {
        display: inline-block;
        margin-right: 10px !important;
    }

    &--r30 {
        display: inline-block;
        margin-right: 30px !important;
    }

    &--l10 {
        display: inline-block;
        margin-left: 10px !important;
    }

    &--l30 {
        display: inline-block;
        margin-left: 30px !important;
    }

}
