@import '../variables';

.panel {
  position: relative;

  &--checkout {
    background-color: $white;
  }

  &__section {
    &--total {
      height: 135px;
      background-color: $white;
      padding: 10px 25px;

      @media screen and (max-height: 500px) {
        height: 22vh;
      }
    }

    &--numpad {
      height: 500px;
      background-color: $pale-grey-two;

      @media screen and (max-height: 700px) {
        height: 65vh;
      }
    }

    &--numpad-animation {
      height: 570px;
      background: linear-gradient(270deg, lightgreen, #e9ebef);
      background-size: 400% 400%;
      -webkit-animation: AnimationName 2s ease infinite;
      -moz-animation: AnimationName 2s ease infinite;
      -o-animation: AnimationName 2s ease infinite;
      animation: AnimationName 2s ease infinite;

      @-webkit-keyframes AnimationName {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
      @-moz-keyframes AnimationName {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
      @-o-keyframes AnimationName {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
      @keyframes AnimationName {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }

      @media screen and (max-height: 700px) {
        height: 78vh;
      }
    }
  }

  &--sidebar {
    background-color: $white;
    width: 472px;
    height: 100%;
    padding: 0 22.5px 0 24.5px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    overflow-y: auto;
  }

  &--signSidebar {
    background-color: $white;
    width: 472px;
    height: 100%;
    padding: 0 22.5px 0 24.5px;

    margin-right: 0px;
    z-index: 2;
    position: absolute;
    top: 0px;
    bottom: 0px;
    transition: -webkit-transform 10.3s ease-out 0s;
    will-change: transform;
    overflow: hidden;
    background: rgb(255, 255, 255);
    right: 0px;
    transform: translateX(0%);
    box-shadow: rgba(0, 0, 0, 0.15) -2px 2px 4px;
    box-shadow: rgba(0, 0, 0, 0.15) -2px 2px 4px;
  }

  &--form {
    background-color: $white;
    padding: 0 22.5px 0 24.5px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 99;
    overflow-y: auto;
  }

  &__title {
    color: $black;
    padding: 21px 0;
    font-size: 18px;
    font-weight: 500;
    border-bottom: solid 1px $pale-grey-three;
  }

  &__content {
    padding: 25px 0;
  }

  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
  }
}
