// Required
@import "node_modules/bootstrap/scss/functions";
@import "./variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/list-group";
@import "./forms";
@import "./nav";

// Overrides

.modal-dialog {
  &--full {
    width: 100vw;
    height: 100vh;
    margin: 0;
    max-width: none !important;

    .modal-content {
      height: 100vh;
      background-color: white;
    }
  }

  &--okto {
    .modal-content {
      height: 250px;
    }
  }

  &--centered {
    .modal-title {
      width: 100%;
      text-align: center;
    }

    .modal-content {
      text-align: center;
      padding: 0 50px 50px 50px;
    }

    .modal-body {
      margin: auto;
      max-width: 215px;
    }

    .modal-footer {
      justify-content: center;
    }
  }
}

.modal-dialog-midnight {
  &--full {
    width: 100vw;
    height: 100vh;
    margin: 0;
    max-width: none !important;

    .modal-content {
      height: 100vh;
      background-color: #242424;
    }
  }

  &--okto {
    .modal-content {
      height: 250px;
    }
  }

  &--centered {
    .modal-title {
      width: 100%;
      text-align: center;
    }

    .modal-content {
      text-align: center;
      padding: 0 50px 50px 50px;
    }

    .modal-body {
      margin: auto;
      max-width: 215px;
    }

    .modal-footer {
      justify-content: center;
    }
  }
  &--centered {
    
    .modal-title {
      width: 100%;
      text-align: center;
    }

    .modal-content {
      text-align: center;
      padding: 0 50px 50px 50px;
      background-color: #242424;
    }

    .modal-body {
      margin: auto;
      max-width: 215px;
    }

    .modal-footer {
      justify-content: center;
    }
  }
}


.modal-dialog-ultraviolet {
  &--full {
    width: 100vw;
    height: 100vh;
    margin: 0;
    max-width: none !important;

    .modal-content {
      height: 100vh;
      background-color: #21253D;
    }
  }

  &--okto {
    .modal-content {
      height: 250px;
    }
  }

  &--centered {
    .modal-title {
      width: 100%;
      text-align: center;
    }

    .modal-content {
      text-align: center;
      padding: 0 50px 50px 50px;
    }

    .modal-body {
      margin: auto;
      max-width: 215px;
    }

    .modal-footer {
      justify-content: center;
    }
  }
  &--centered {
    
    .modal-title {
      width: 100%;
      text-align: center;
    }

    .modal-content {
      text-align: center;
      padding: 0 50px 50px 50px;
      background-color: #21253D;
    }

    .modal-body {
      margin: auto;
      max-width: 215px;
    }

    .modal-footer {
      justify-content: center;
    }
  }
}

.modal .modal-header {
  .close {
    box-shadow: none;
    background-color: transparent;
    border: none;
    color: $brand-color;
    font-size: 25px;
    line-height: 22px;
    cursor: pointer;
    outline: none;
  }

  .sr-only {
    display: none;
  }
}

.form-group {
  margin-bottom: 1.5rem;
}
