@import '../variables';

.layout {
    padding: 9px 20px;

    &__container {
        width: 1366px;
        max-width: 100%;
        margin:0 auto;
    }

    &__content {
        padding: 20px 0;
    }

    &--dashboard {
        display: flex;
        flex-direction: row;
    }

    &--login {
        width: 100%;
        height: 100%;
        //background: url(../assets/login-bg.png) no-repeat center center transparent;
        background-attachment: fixed;
        z-index: 2;
        background-size: cover;
    }

    &--loginAlt {
        width: 100%;
        height: 100%;
        background: url(../assets/login-bg.png) no-repeat center center;
        background-attachment: fixed;
        background-size: cover;
    }

    &--login-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        //background: url(../assets/login-bg.png) no-repeat center center transparent;
        background-size: cover;
      }


    &__column {
        position: relative;
        &--dash {
            min-height: 670px;

            &:first-child {
                max-width: 970px;
                width: calc(100% - 330px);
               // background-color: red;
                margin-right: 20px;

                @media screen and (max-width: $lg) {
                    width: calc(100% - 330px);
                    margin-right: 10px;
                }
            }

            &:last-child {
                max-width: 622px;
                min-width: 330px;
                // width: 31%;
                // background-color: red;

                @media screen and (max-width: $lg) {
                    min-width: 330px;
                }
            }
        }

        &--login {
            width: 398px;
            max-width: 100%;
            margin-left: 128px;

            @media screen and (max-width: $sm) {
                margin-left: 0;
            }
        }

        &--full {
            width: 100%;
            max-width: 100%;
        }
    }

}
