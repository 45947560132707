@import "../variables";

.total {
    padding: 0 30px 0 25px;

    &__row{
        display: flex;
        justify-content: flex-end;

        &--inventory {
            justify-content: center;
            padding-top: 30px;

        }
        &--inventoryLabel {
            justify-content: center;
            padding-top: 10px;

        }
    }
    &__separator{
        background-color: #ced6e0;
        height: 1px;
        margin: 13px 0 11px;
    }

    &__label {
        @extend .text--large;
    }

    &__value {
        @extend .text--large, .text--medium;
        margin-left: 5px;
    }
 }
