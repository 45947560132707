@import '../variables';

.split {


    &__title {
        padding: 20px;
        margin-bottom: 20px;
        text-align: center;
        border-bottom: 1px solid $pale-grey-three;
    }

    &__body {
        //max-width: 405px;
        max-width: 455px;
        margin: 60px auto 0;
    }

    &__row {
        display: flex;
        align-items: center;
        margin:30px 0;
    }

    &__label {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 10px;
            width: 25px;
            height: 25px;
        }

        margin-right: 12px;
    }

    &__customer {
        cursor: pointer;
        color: $brand-color;
        margin-left: 30px;
        display: inline-block;
        border-bottom: 1px dashed;
    }

    &__remaining {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 84px 0 40px 0;
        color: $brand-color;

        &--danger {
            color: $danger;
        }
    }

    &__remainingLabel {
        @extend .text--modalTitle;
    }

    &__remainingValue {
        @extend .text--modalTitle, .text--bold;
        margin-left: 5px;
    }
}
