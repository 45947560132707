@import "../variables";

.editable {
    
    width: 100%;
    text-align: center;
    display: inline-block;
    position: relative;

    &__input {
        /*left: 0;
        top: 0;
        position: absolute;
        max-width: 100%;*/
        width: 72px;
        font-size: 14px;
        line-height: 1;
        padding: 3px 5px;
        border-radius: 5px;
        text-align: center;
       transition: all 0.2s ease-in-out;
        background: $white;

        outline: none;

        &--display{
            border: 1px solid #ced6e0;
            color: $cool-grey;
        }

        &--edit{
            transform: scale(1.5);
            background: $white;
            border: 1px solid $brand-color-light;
            color: $brand-color;
        }

        &--error{
            border: 1px solid $danger;
            color: $danger;
        }

        &--bundle{
            transform: translateY(-5.5px);
            background: $white;
            border: 6px solid #00BF6C;
            color: #00BF6C;
        }

        &--mass{
            transform: translateY(-5.5px);
            
            background: $white;
            border: 6px dashed $brand-color;
            color: $brand-color;
        }
    }

    &__currency {
        margin-left: 6px;
        font-size: 14px;

        @extend .text--small;
        @extend .text--gray;


    }
}
