@import "../variables";

.quick-products {
  margin-top: 15px;
  overflow: auto;
  min-height: 300px;

  .nav-tabs {
    min-width: 825px;

    @media screen and (max-width: $lg) {
      min-width: 775px;
    }
  }

  &__tab {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 230px;
    min-width: 825px;
    overflow: auto;

    @media screen and (max-width: $lg) {
      min-width: 775px;
    }
  }

  &--sidebar {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .quick-product {
      position: relative;
      width: 114px;
      height: 114px;
      border-radius: 18px;
      border: solid 2px #dfe4ea;
      background-image: linear-gradient(to bottom, $white, #f1f2f6);

      display: flex;
      flex-direction: column;
      align-items: center;

      margin: 0 25px 25px 0;

      /* &:nth-child(3) {
                margin-right: 0;
            }*/
    }
  }

  &__product-search {

    /* ALT
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px dashed #2684FF;
    */
    position: absolute;
    float: right;
    width: 381px;
    right: 0px;
    padding: 7px;
    background-color: $pale-grey-two;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: $xl) {
      width: 120px;
      height: 0px;
      //margin: 7px;
      padding: 0px;
      background-color: none;
    }
  }

  .search--box {
    color: black;
    @media screen and (max-width: $xl) {
      display: none;
    }
  }

  &__buttons {
    display: flex;
  }

  .nav-tabs {
    color: black
  }

  .icon--box {
    margin-left: 10px;
    margin-bottom: -38px;
  }
}

.quick-product {
  position: relative;
  width: 114px;
  height: 100px;
  border-radius: 18px;
  border: solid 2px #dfe4ea;
  background-image: linear-gradient(to bottom, $white, #f1f2f6);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 22px 15px 0;

  /*  &:nth-child(6) {
        margin-right: 0;
    }*/

  &__badge {
    @extend .text--smaller, .text--medium;
    margin-top: 12px;
    width: 30px;
    height: 30px;
    background-color: $brand-color;
    border-radius: 50%;
    color: $white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--folder {
      background-color: $quickFolderBG;
    }

    &--variation {
      background: #f06;
      background-image: linear-gradient(45deg, #fff722, #ff26f9);
    }

    &--plus {
      background-color: $light-periwinkle;
      font-size: 25px !important;
    }
  }

  &__label {
    margin-top: 10px;
    padding: 0 8px;
    font-size: 12px;
    // text-transform: uppercase;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    text-align: center;
  }

  &__button {
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: -15px;
    &--delete {
      background-color: $danger;
    }
    &--edit {
      background-color: $success;
    }
  }
}
