.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1.3rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #b0b7c7;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #4c84ff;
    outline: 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .form-control::placeholder {
    color: #9196a3;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #9196a3;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 2.375rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 1.5rem) * 3 / 4 + 2.3rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.3rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2.3rem/calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 1.5rem) * 3 / 4 + 2.3rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.3rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 2.3rem/calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #4c84ff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.3rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #b0b7c7;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.3rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #4c84ff;
    background-color: #4c84ff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #4c84ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: white;
    border-color: white; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #4c84ff;
  background-color: #4c84ff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(76, 132, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(76, 132, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(76, 132, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(76, 132, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 2.3rem 0.75rem 1.3rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.3rem center/8px 10px;
  border: 1px solid #b0b7c7;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #4c84ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.3rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #4c84ff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1.3rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #b0b7c7;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1.5rem);
    padding: 0.75rem 1.3rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #4c84ff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: white; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #4c84ff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: white; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #4c84ff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: white; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #4c84ff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0050ff;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4c84ff;
  border-color: #4c84ff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #4c84ff;
    border-color: #4c84ff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #284585;
  background-color: #cdddff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #284585;
    background-color: #b4ccff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #284585;
    border-color: #284585; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.75rem 1.3rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #b0b7c7;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #4c84ff;
    outline: 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .form-control::placeholder {
    color: #9196a3;
    font-weight: 300;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #9196a3;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 2.375rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 1.5rem) * 3 / 4 + 2.3rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.3rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2.3rem/calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 1.5rem) * 3 / 4 + 2.3rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.3rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 2.3rem/calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00bf6c !important;
  background-color: #00bf6c !important; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.2rem !important;
  outline: none !important;
  border-color: #b0b7c7 !important; }

.custom-control-label {
  padding-top: 5px; }

.custom-control-label:after {
  top: 0.3rem !important;
  left: -1.4rem !important; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 17px 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #0069b3; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    outline: none;
    text-decoration: none;
    color: gray;
    font-size: 12.5px;
    font-weight: 500;
    height: 53px;
    width: 109px;
    text-align: center; }
    @media screen and (max-width: 1336px) {
      .nav-tabs .nav-link {
        width: 120px; } }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #ffffff;
    background-color: #0069b3;
    border-color: #0069b3; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4c84ff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.modal-dialog--full {
  width: 100vw;
  height: 100vh;
  margin: 0;
  max-width: none !important; }
  .modal-dialog--full .modal-content {
    height: 100vh;
    background-color: white; }

.modal-dialog--okto .modal-content {
  height: 250px; }

.modal-dialog--centered .modal-title {
  width: 100%;
  text-align: center; }

.modal-dialog--centered .modal-content {
  text-align: center;
  padding: 0 50px 50px 50px; }

.modal-dialog--centered .modal-body {
  margin: auto;
  max-width: 215px; }

.modal-dialog--centered .modal-footer {
  justify-content: center; }

.modal-dialog-midnight--full {
  width: 100vw;
  height: 100vh;
  margin: 0;
  max-width: none !important; }
  .modal-dialog-midnight--full .modal-content {
    height: 100vh;
    background-color: #242424; }

.modal-dialog-midnight--okto .modal-content {
  height: 250px; }

.modal-dialog-midnight--centered .modal-title {
  width: 100%;
  text-align: center; }

.modal-dialog-midnight--centered .modal-content {
  text-align: center;
  padding: 0 50px 50px 50px; }

.modal-dialog-midnight--centered .modal-body {
  margin: auto;
  max-width: 215px; }

.modal-dialog-midnight--centered .modal-footer {
  justify-content: center; }

.modal-dialog-midnight--centered .modal-title {
  width: 100%;
  text-align: center; }

.modal-dialog-midnight--centered .modal-content {
  text-align: center;
  padding: 0 50px 50px 50px;
  background-color: #242424; }

.modal-dialog-midnight--centered .modal-body {
  margin: auto;
  max-width: 215px; }

.modal-dialog-midnight--centered .modal-footer {
  justify-content: center; }

.modal-dialog-ultraviolet--full {
  width: 100vw;
  height: 100vh;
  margin: 0;
  max-width: none !important; }
  .modal-dialog-ultraviolet--full .modal-content {
    height: 100vh;
    background-color: #21253D; }

.modal-dialog-ultraviolet--okto .modal-content {
  height: 250px; }

.modal-dialog-ultraviolet--centered .modal-title {
  width: 100%;
  text-align: center; }

.modal-dialog-ultraviolet--centered .modal-content {
  text-align: center;
  padding: 0 50px 50px 50px; }

.modal-dialog-ultraviolet--centered .modal-body {
  margin: auto;
  max-width: 215px; }

.modal-dialog-ultraviolet--centered .modal-footer {
  justify-content: center; }

.modal-dialog-ultraviolet--centered .modal-title {
  width: 100%;
  text-align: center; }

.modal-dialog-ultraviolet--centered .modal-content {
  text-align: center;
  padding: 0 50px 50px 50px;
  background-color: #21253D; }

.modal-dialog-ultraviolet--centered .modal-body {
  margin: auto;
  max-width: 215px; }

.modal-dialog-ultraviolet--centered .modal-footer {
  justify-content: center; }

.modal .modal-header .close {
  box-shadow: none;
  background-color: transparent;
  border: none;
  color: #0069b3;
  font-size: 25px;
  line-height: 22px;
  cursor: pointer;
  outline: none; }

.modal .modal-header .sr-only {
  display: none; }

.form-group {
  margin-bottom: 1.5rem; }

.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff; }

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em; }

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  margin-left: -160px; }

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em; }

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em; }

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  margin-left: -160px; }

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em; }

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0; }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0; }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0; }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial; } }

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 20px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  cursor: pointer;
  direction: ltr; }

.Toastify__toast--rtl {
  direction: rtl; }

.Toastify__toast--default {
  background: #fff;
  color: #aaa; }

.Toastify__toast--info {
  background: #3498db; }

.Toastify__toast--success {
  background: #07bc0c; }

.Toastify__toast--warning {
  background: #f1c40f; }

.Toastify__toast--error {
  background: #e74c3c; }

.Toastify__toast-body {
  margin: auto 0;
  -ms-flex: 1;
  flex: 1; }

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0; } }

.Toastify__close-button {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
  align-self: flex-start; }

.Toastify__close-button--default {
  color: #000;
  opacity: 0.3; }

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1; }

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1); }
  100% {
    transform: scaleX(0); } }

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left; }

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards; }

.Toastify__progress-bar--controlled {
  transition: transform .2s; }

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right; }

.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft; }

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight; }

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown; }

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp; }

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft; }

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight; }

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp; }

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown; }

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn; }

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut; }

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.Toastify__flip-enter {
  animation-name: Toastify__flipIn; }

.Toastify__flip-exit {
  animation-name: Toastify__flipOut; }

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0); } }

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0); } }

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft; }

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight; }

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown; }

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp; }

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft; }

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight; }

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp; }

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown; }

/*# sourceMappingURL=ReactToastify.css.map */
.react-datepicker-wrapper {
  width: 100%;
  position: relative; }
  .react-datepicker-wrapper:before {
    display: block;
    content: " ";
    background-image: url("../assets/svgs/calendarInput.svg");
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 10px;
    z-index: 1;
    top: 8px; }

/* Color palette */
/* Text styles */
/* Breakpoints */
html, body {
  height: 100% !important; }

body {
  width: 100%;
  height: 100%;
  background: #f7f8f9;
  overflow-y: visible;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.height--full {
  position: absolute;
  width: 100%;
  height: 100%; }

.height--full--scroll {
  overflow-y: auto;
  position: absolute;
  width: 100%;
  height: 100%; }

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a29f99; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #a29f99; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #a29f99; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #a29f99; }

@media print {
  body * {
    visibility: hidden; } }

/* Color palette */
/* Text styles */
/* Breakpoints */
.layout {
  padding: 9px 20px; }
  .layout__container {
    width: 1366px;
    max-width: 100%;
    margin: 0 auto; }
  .layout__content {
    padding: 20px 0; }
  .layout--dashboard {
    display: flex;
    flex-direction: row; }
  .layout--login {
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    z-index: 2;
    background-size: cover; }
  .layout--loginAlt {
    width: 100%;
    height: 100%;
    background: url(../assets/login-bg.png) no-repeat center center;
    background-attachment: fixed;
    background-size: cover; }
  .layout--login-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-size: cover; }
  .layout__column {
    position: relative; }
    .layout__column--dash {
      min-height: 670px; }
      .layout__column--dash:first-child {
        max-width: 970px;
        width: calc(100% - 330px);
        margin-right: 20px; }
        @media screen and (max-width: 1200px) {
          .layout__column--dash:first-child {
            width: calc(100% - 330px);
            margin-right: 10px; } }
      .layout__column--dash:last-child {
        max-width: 622px;
        min-width: 330px; }
        @media screen and (max-width: 1200px) {
          .layout__column--dash:last-child {
            min-width: 330px; } }
    .layout__column--login {
      width: 398px;
      max-width: 100%;
      margin-left: 128px; }
      @media screen and (max-width: 540px) {
        .layout__column--login {
          margin-left: 0; } }
    .layout__column--full {
      width: 100%;
      max-width: 100%; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.container {
  width: 1366px;
  max-width: 100%;
  margin: 0 auto; }
  .container--nopadding {
    padding: 0 10px; }

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 10px; }
  .header__item {
    margin-left: 17px; }
    .header__item:first-child {
      margin-left: 0; }
  .header__left {
    display: flex;
    flex-direction: row; }
  .header__right {
    display: flex;
    flex-direction: row; }

.label-selection {
  border: 2px dotted #0069b3;
  border-radius: 10px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px; }
  .label-selection__active {
    border: 2px dotted #0069b3; }
  .label-selection__inactive {
    border: 2px solid gray; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.btn {
  position: relative;
  height: 50px;
  padding: 14px 40px;
  min-width: 159px;
  max-width: 100%;
  font-size: 16px;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid transparent;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  outline: none; }
  .btn:disabled {
    opacity: 0.3; }
  .btn--link {
    border: 2px solid transparent !important;
    background-color: transparent !important;
    color: #0069b3; }
    .btn--link .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px; }
    .btn--link span {
      vertical-align: middle;
      display: inline-block; }
    .btn--link.btn--primary {
      color: #0069b3; }
      .btn--link.btn--primary:hover {
        color: #0076CA !important; }
  .btn--primary {
    border: 2px solid #0069b3;
    background-color: #0069b3;
    color: #ffffff; }
    .btn--primary:hover {
      background-color: #0076CA; }
    .btn--primary.btn--outline {
      color: #0069b3;
      background-color: transparent; }
      .btn--primary.btn--outline:hover {
        background-color: #0069b3;
        color: #ffffff; }
      .btn--primary.btn--outline.active {
        background-color: #0069b3;
        color: #ffffff; }
      .btn--primary.btn--outline.selected {
        background-color: #00bf6c;
        border: 2px solid #00bf6c;
        color: #ffffff; }
    .btn--primary.btn--filter {
      color: #0069b3;
      background-color: transparent;
      min-width: 55px;
      min-height: 40px;
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 10px; }
      .btn--primary.btn--filter:hover {
        background-color: #0069b3;
        color: #ffffff; }
      .btn--primary.btn--filter.active {
        background-color: #0069b3;
        color: #ffffff; }
      .btn--primary.btn--filter.selected {
        background-color: #00bf6c;
        border: 2px solid #00bf6c;
        color: #ffffff; }
    .btn--primary.btn--cents {
      color: #0069b3;
      background-color: transparent;
      min-width: 55px;
      min-height: 55px;
      padding: 0px; }
      .btn--primary.btn--cents:hover {
        background-color: #0069b3;
        color: #ffffff; }
      .btn--primary.btn--cents.active {
        background-color: #0069b3;
        color: #ffffff; }
      .btn--primary.btn--cents.selected {
        background-color: #00bf6c;
        border: 2px solid #00bf6c;
        color: #ffffff; }
  .btn--small {
    height: 40px;
    margin-left: 10px;
    padding: 9px 0;
    min-width: 100px;
    border: 2px solid #0069b3;
    background-color: #0069b3;
    color: #ffffff; }
    .btn--small:hover {
      background-color: #0076CA; }
    .btn--small.btn--outline {
      color: #0069b3;
      background-color: #ffffff; }
      .btn--small.btn--outline:hover {
        background-color: #0069b3;
        color: #ffffff; }
      .btn--small.btn--outline.active {
        background-color: #0069b3;
        color: #ffffff; }
  .btn--danger {
    background-color: #ff433d;
    color: #ffffff; }
    .btn--danger:hover {
      background-color: #e83d38; }
  .btn--white {
    border: 2px solid #ffffff;
    background-color: transparent;
    color: #ffffff; }
    .btn--white:hover {
      background-color: #e9ebef;
      color: #0069b3; }
  .btn--success {
    background-color: #00bf6c;
    color: #ffffff; }
    .btn--success:hover {
      background-color: #009d59; }
  .btn--warning {
    background-color: #ffc931;
    color: #ffffff; }
    .btn--warning:hover {
      background-color: #e8b72d; }
  .btn--round {
    height: 45px;
    border-radius: 30px;
    font-size: 14px; }
  .btn--fullwidth {
    width: 100%; }
  .btn--login {
    background-color: transparent;
    display: block;
    height: 60px;
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    border-radius: 50%;
    margin-left: 165px;
    padding: 0px;
    border: 2px solid #ffffff;
    background-color: transparent;
    color: white; }
    .btn--login:hover {
      background-color: rgba(255, 255, 255, 0.2); }

.btninpt {
  position: relative;
  height: 50px;
  padding: 14px 0px;
  width: 90px;
  text-align: center;
  height: 45px;
  font-size: 16px;
  color: #ffffff;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  outline: none; }
  .btninpt:disabled {
    opacity: 0.3; }
  .btninpt--primary {
    border: 2px solid #0069b3;
    background-color: transparent;
    color: #0069b3; }
    .btninpt--primary.btn--outline {
      color: #0069b3;
      background-color: transparent; }
  .btninpt--selected {
    border: 2px solid #00bf6c;
    color: #00bf6c; }

.height--full {
  height: 100%; }

.form__input {
  max-width: 422px;
  margin: auto; }
  .form__input--barcode {
    position: relative;
    max-width: 100%; }
    .form__input--barcode .icon {
      position: absolute;
      right: 8px;
      top: 8px;
      color: #b0b7c7; }

.form__row {
  margin-bottom: 20px; }
  .form__row--split {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  .form__row--sidebar {
    margin-bottom: 10px; }
  .form__row .form-label {
    display: block;
    margin-bottom: 5px; }

.form__label {
  margin-bottom: 0; }
  .form__label--sidebar {
    margin-bottom: 0.3rem;
    display: block; }

.form__error {
  margin-top: 0.3rem; }

.form__switchWrapper {
  margin-bottom: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.form__weightWrapper {
  margin-bottom: 30px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.form__switch {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.form__switchLabel {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.form__switchControl {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .form__switchControl span {
    margin: 0 5px; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.margin {
  position: relative; }
  .margin--t10 {
    display: inline-block;
    margin-top: 10px; }
  .margin--t20 {
    display: inline-block;
    margin-top: 20px; }
  .margin--t30 {
    display: inline-block;
    margin-top: 30px; }
  .margin--b10 {
    display: inline-block;
    margin-bottom: 10px; }
  .margin--b30 {
    display: inline-block;
    margin-bottom: 30px; }
  .margin--50 {
    margin: 50px; }
  .margin--r10 {
    display: inline-block;
    margin-right: 10px !important; }
  .margin--r30 {
    display: inline-block;
    margin-right: 30px !important; }
  .margin--l10 {
    display: inline-block;
    margin-left: 10px !important; }
  .margin--l30 {
    display: inline-block;
    margin-left: 30px !important; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.logo {
  margin: 86px 0 0px;
  display: inline-block;
  vertical-align: middle; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 300;
  font-size: 16px;
  cursor: pointer; }
  .item__label {
    color: #000000; }
    .item__label--scanner {
      font-size: 14px; }
    .item__label--letter {
      font-size: 14px;
      font-weight: normal;
      color: #ffffff; }
  .item__icon {
    margin-right: 5px; }
    .item__icon--menu {
      margin-right: 22px; }
  .item__button {
    width: 36.4px;
    height: 35.3px;
    border-radius: 5px;
    background-color: #bec4d1;
    display: flex;
    align-items: center;
    justify-content: center; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.editable {
  width: 100%;
  text-align: center;
  display: inline-block;
  position: relative; }
  .editable__input {
    /*left: 0;
        top: 0;
        position: absolute;
        max-width: 100%;*/
    width: 72px;
    font-size: 14px;
    line-height: 1;
    padding: 3px 5px;
    border-radius: 5px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    background: #ffffff;
    outline: none; }
    .editable__input--display {
      border: 1px solid #ced6e0;
      color: #9196a3; }
    .editable__input--edit {
      transform: scale(1.5);
      background: #ffffff;
      border: 1px solid #0076CA;
      color: #0069b3; }
    .editable__input--error {
      border: 1px solid #ff433d;
      color: #ff433d; }
    .editable__input--bundle {
      transform: translateY(-5.5px);
      background: #ffffff;
      border: 6px solid #00BF6C;
      color: #00BF6C; }
    .editable__input--mass {
      transform: translateY(-5.5px);
      background: #ffffff;
      border: 6px dashed #0069b3;
      color: #0069b3; }
  .editable__currency {
    margin-left: 6px;
    font-size: 14px; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.notification-table--admin {
  position: relative;
  height: 100%; }

.notification-table--vat {
  margin: auto;
  max-width: 520px; }

.notification-table--card {
  overflow: auto;
  max-height: 175px; }

.notification-table__wrapper--details {
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25); }
  .notification-table__wrapper--details .col:first-child {
    border-right: 1px solid rgba(98, 125, 183, 0.25); }

.notification-table__amount {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .notification-table__amount .icon {
    margin-right: 10px; }

.notification-table__toolbar {
  background-color: rgba(176, 183, 199, 0.08);
  padding: 10px;
  height: auto;
  display: flex;
  justify-content: flex-end; }
  .notification-table__toolbar input {
    max-width: 350px; }
  .notification-table__toolbar--filters {
    justify-content: space-between; }

.notification-table__cardsDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  background-color: #0069b3;
  height: 40px;
  background-color: #f7f8f9;
  padding: 12px 10px;
  height: auto; }

.notification-table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  background-color: #0069b3;
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }
  .notification-table__header--admin {
    background-color: rgba(176, 183, 199, 0.08);
    padding: 22px 10px;
    height: auto;
    align-items: flex-start; }
  .notification-table__header--light {
    background-color: #f7f8f9;
    padding: 12px 10px;
    height: auto; }
    .notification-table__header--light-fill {
      box-shadow: 0 1px 6px 0 rgba(98, 125, 183, 0.25); }
  .notification-table__header--details {
    background-color: white;
    padding: 22px 10px;
    height: auto; }
  .notification-table__header--modal {
    background-color: rgba(176, 183, 199, 0.08);
    padding: 3px 10px;
    height: auto; }
  .notification-table__header--modal-alt {
    background-color: rgba(176, 183, 199, 0.08);
    padding: 3px 10px;
    height: auto;
    border-radius: 0;
    border-top: 1px solid #9196a3;
    border-bottom: 1px solid #9196a3; }

.notification-table__username {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 4px 0; }
  .notification-table__username .avatar {
    margin-right: 10px; }

.notification-table__row {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 18px;
  padding: 0 32px; }
  .notification-table__row:last-child .table__cell {
    border-bottom: none; }
  .notification-table__row--card {
    padding: 0; }
  .notification-table__row--stripped:nth-child(odd) {
    background-color: rgba(0, 105, 179, 0);
    border-bottom: none !important; }
  .notification-table__row--stripped:nth-child(even) {
    background-color: rgba(0, 105, 179, 0.05);
    border-bottom: none !important; }
  .notification-table__row--admin {
    padding: 0 10px; }
  .notification-table__row:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: rgba(128, 128, 128, 0.2) !important; }
  .notification-table__row.selected {
    background-color: #00bf6c !important; }
    .notification-table__row.selected .table__cell {
      color: #ffffff !important; }
  .notification-table__row.warning {
    background-color: #e8b72d !important; }
    .notification-table__row.warning .table__cell {
      color: #ffffff !important; }
  .notification-table__row.skroutz {
    background-color: #f68b24 !important; }
    .notification-table__row.skroutz .table__cell {
      color: #ffffff !important; }
  .notification-table__row.danger {
    background-color: #ff433d !important; }
    .notification-table__row.danger .table__cell {
      color: #ffffff !important; }
  .notification-table__row.selected-blue {
    background-color: #0069b3 !important; }
    .notification-table__row.selected-blue .table__cell {
      color: #ffffff !important; }

.notification-table__heading {
  color: #ffffff;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
  flex: 100 0 auto;
  padding: 9.5px 5px 11.5px; }
  .notification-table__heading .custom-control {
    margin-top: -5px; }
  .notification-table__heading--centered {
    text-align: center;
    align-items: center; }
  .notification-table__heading--admin {
    color: #000000;
    padding: 12px 10px 12px; }
  .notification-table__heading--date {
    color: #000000; }
    .notification-table__heading--date .react-datepicker-wrapper:before {
      top: 18px; }
    .notification-table__heading--date input {
      height: 38px;
      padding: 8px 25px 8px 8px;
      margin-top: 8px; }
  .notification-table__heading--vat {
    font-size: 16px;
    align-items: center; }

.notification-table__cell {
  font-size: 14px;
  line-height: 19px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
  height: 40px;
  flex: 100 0 auto;
  padding: 8.5px 5px 10.5px;
  border-bottom: none !important; }
  .notification-table__cell .custom-control {
    margin-top: -5px; }
  .notification-table__cell--centered {
    text-align: center;
    align-items: center; }
  .notification-table__cell--align-right {
    text-align: right;
    align-items: flex-end; }
  .notification-table__cell--card {
    height: auto;
    padding: 3px 5px 4px; }
  .notification-table__cell--admin {
    color: #000000;
    padding: 12px 10px 12px; }
  .notification-table__cell--modal {
    color: #000000;
    padding: 5px 10px 5px;
    align-items: center;
    justify-content: center; }
    .notification-table__cell--modal .form-control {
      max-width: 100%;
      max-height: 100%; }
  .notification-table__cell--modalFooter {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
  .notification-table__cell--name {
    padding: 0 10px 0;
    display: flex;
    justify-content: center; }
  .notification-table__cell--start {
    width: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: center; }
  .notification-table__cell--actions {
    width: 100px;
    flex: 0 0 100px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0; }
    .notification-table__cell--actions .btn--withIcon {
      padding: 0 5px;
      min-width: 120px;
      height: 40px; }
  .notification-table__cell--small {
    width: 10px;
    flex: 0 0 10px;
    margin-right: 15px; }
  .notification-table__cell--number {
    width: 75px;
    flex: 0 0 75px;
    margin-right: 15px; }
  .notification-table__cell--amount {
    width: 125px;
    flex: 0 0 115px;
    margin-right: 15px; }
  .notification-table__cell--large {
    width: 175px;
    flex: 0 0 175 auto; }
  .notification-table__cell--fulWidth {
    flex: 1; }
  .notification-table__cell--noborder {
    border: none; }

.notification-table__filters-wrapper {
  display: flex; }

.notification-table__filter {
  position: relative; }
  .notification-table__filter .icon {
    position: absolute;
    right: 8px;
    top: 22px; }
  .notification-table__filter--calendar {
    max-width: 175px;
    margin-right: 15px; }
  .notification-table__filter--select {
    margin-top: 8px;
    width: 100%; }

.notification-table__body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25);
  overflow: auto;
  min-height: 150px; }
  .notification-table__body--products {
    height: 295px;
    max-height: 295px;
    overflow: auto; }
  .notification-table__body--details {
    background-color: transparent;
    box-shadow: none; }

.notification-table__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0 10px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25); }

.notification-table__input {
  width: 100%;
  outline: none;
  margin-top: 7px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 38px;
  border: solid 1px #b0b7c7;
  transition: all 0.3s ease-in-out;
  padding: 8px 25px 8px 8px; }
  .notification-table__input:focus {
    box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25);
    border: solid 1px #0069b3; }

.notification-table__pagination .sr-only {
  display: none; }

.notification-table__pagination .page-link {
  text-decoration: none;
  box-shadow: none;
  outline: none; }

.notification-table__empty {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%; }

.notification-table__loading {
  color: #0069b3;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%; }

.notification-table__excel_loading {
  color: #1d6f41;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%; }

.notification-table__excel_loading_text {
  color: #9196a3;
  margin-left: 15px; }

.notification-table__excel_loading_complete {
  color: #000000;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%; }

.extra_row {
  padding: 0 20px 22px;
  background-color: rgba(176, 183, 199, 0.08);
  height: auto;
  align-items: flex-start;
  display: flex;
  justify-content: flex-end; }

.notification-box {
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25); }
  .notification-box .col:first-child {
    border-right: 1px solid rgba(98, 125, 183, 0.25); }

.notify {
  background-color: white;
  padding: 0px;
  color: black; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.tooltip {
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .tooltip__item {
    cursor: pointer !important;
    padding: 5px 10px; }
  .tooltip__separator {
    margin: 0 13px;
    width: 1px;
    height: 22px;
    background-color: white; }
  .tooltip.start {
    padding-left: 0; }
    .tooltip.start .tooltip__item {
      padding-left: 0; }

/* Tooltip container */
/* Tooltip text */
.tooltipHover .tooltipHovertext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  margin-left: 40px !important;
  margin-top: -10px !important; }

.tooltipHover .tooltipHovertext.Cards {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  margin-left: 260px !important;
  margin-top: -10px !important; }

.tooltipHover.salereport .tooltipHovertext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  margin-left: 3px !important;
  margin-top: -35px !important; }

.tooltipHover.warehouseproducts .tooltipHovertext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  margin-left: -47px !important;
  margin-top: -35px !important; }

.tooltipHover.salesreceipts .tooltipHovertext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  margin-left: -75px !important;
  margin-top: -40px !important; }

.tooltipHover.addeditproduct .tooltipHovertext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  margin-left: -47px !important;
  margin-top: -55px !important; }

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipHover:hover {
  cursor: pointer; }

.tooltipHover:hover .tooltipHovertext {
  visibility: visible;
  opacity: 1; }

.tooltipHovertext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; }

.table__heading.table__heading--admin.tooltipHover span {
  margin: auto;
  margin-top: 25px; }

.table__heading.table__heading--admin.tooltipHover.salereport span {
  margin: auto; }

.table__heading.table__heading--admin.tooltipHover.warehouseproducts {
  padding: 0;
  margin: auto 30px auto auto;
  width: auto; }

.table__heading.table__heading--admin.tooltipHover.warehouseproducts span {
  margin: auto; }

.table__heading.table__heading--admin.tooltipHover.addeditproduct {
  padding: 0;
  margin: auto 30px auto auto;
  width: auto; }

.table__heading.table__heading--admin.tooltipHover.addeditproduct span {
  margin: auto; }

.icon {
  width: 32px;
  height: 32px; }
  .icon svg {
    color: inherit;
    fill: currentColor;
    stroke: currentColor;
    width: inherit;
    height: inherit; }
  .icon__button {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: all 0.3s ease-in-out; }
    .icon__button--danger {
      color: #ff433d; }
      .icon__button--danger:hover {
        color: #e83d38; }
    .icon__button .icon {
      margin-right: 17px; }
  .icon--13 {
    width: 13px;
    height: 13px; }
  .icon--small {
    width: 16px;
    height: 16px; }
  .icon--20 {
    width: 20px;
    height: 20px; }
  .icon--18 {
    width: 18px;
    height: 18px; }
  .icon--23 {
    width: 23px;
    height: 23px; }
  .icon--24 {
    width: 24px;
    height: 24px; }
  .icon--38 {
    width: 38px;
    height: 38px; }
  .icon__wrapper--round {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d5e1f9; }
    .icon__wrapper--round--stat {
      background-color: #0069b3; }
  .icon__wrapper--30 {
    width: 30px;
    height: 30px; }
  .icon__wrapper--alert__standby {
    background-color: #009d59; }
  .icon__wrapper--alert__unread {
    animation-name: color;
    animation-duration: 3s;
    animation-iteration-count: infinite; }

@keyframes color {
  0% {
    background-color: rgba(176, 183, 199, 0.58); }
  50% {
    background-color: #ffc931; }
  100% {
    background-color: rgba(176, 183, 199, 0.58); } }
  .icon__wrapper--alert__catastrophic {
    animation-name: colour;
    animation-duration: 1.5s;
    animation-iteration-count: infinite; }

@keyframes colour {
  0% {
    background-color: rgba(176, 183, 199, 0.58); }
  50% {
    background-color: #e83d38; }
  100% {
    background-color: rgba(176, 183, 199, 0.58); } }
  .icon__wrapper--60 {
    width: 60px;
    height: 60px; }
  .icon--white {
    color: white; }
  .icon--notification__standby {
    color: #EAF0FC; }
  .icon--bluey-grey {
    color: #b0b7c7; }
  .icon--brownish-grey {
    color: #707070; }
  .icon--primary {
    color: #0069b3; }
  .icon--danger {
    color: #ff433d; }
  .icon--fill-primary {
    color: transparent; }
    .icon--fill-primary > svg {
      fill: #0069b3; }
  .icon--fill-white {
    color: transparent; }
    .icon--fill-white > svg {
      fill: #ffffff; }
  .icon--fill-danger {
    color: transparent; }
    .icon--fill-danger > svg {
      fill: #ff433d; }
  .icon--box {
    border-radius: 5px;
    background-color: #bec4d1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out; }
    .icon--box:hover {
      background-color: #0069b3; }
    .icon--box.selected {
      background-color: #0069b3; }
    .icon--box.disabled {
      background-color: #bec4d1;
      opacity: 0.2; }
  .icon--round {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: #0069b3; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.table--products {
  overflow: auto; }
  .table--products .table__header,
  .table--products .table__body,
  .table--products .table__footer {
    min-width: 825px; }
    @media screen and (max-width: 1200px) {
      .table--products .table__header,
      .table--products .table__body,
      .table--products .table__footer {
        min-width: 775px; } }

.table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  background-color: #0069b3;
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

.table__row {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 18px;
  padding: 0 32px;
  flex: 0 0 40px;
  min-height: 50px; }
  .table__row:last-child .table__cell {
    border-bottom: none; }
  .table__row--stripped:nth-child(odd) {
    background-color: white; }
  .table__row--stripped:nth-child(even) {
    background-color: rgba(0, 105, 179, 0.05); }
  .table__row--stripped .table__cell {
    border: none; }
  .table__row--bundle {
    background-color: #ffffff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 18px;
    padding: 0 32px;
    flex: 0 0 40px;
    min-height: 50px; }
    .table__row--bundle--0 {
      background-color: rgba(0, 191, 108, 0.15) !important; }
      .table__row--bundle--0:hover {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: rgba(0, 191, 108, 0.25) !important; }
    .table__row--bundle--1 {
      background-color: rgba(255, 201, 49, 0.15) !important; }
      .table__row--bundle--1:hover {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: rgba(255, 201, 49, 0.25) !important; }
    .table__row--bundle--2 {
      background-color: rgba(255, 67, 61, 0.15) !important; }
      .table__row--bundle--2:hover {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: rgba(255, 67, 61, 0.25) !important; }
    .table__row--bundle--3 {
      background-color: rgba(0, 105, 179, 0.15) !important; }
      .table__row--bundle--3:hover {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: rgba(0, 105, 179, 0.25) !important; }
    .table__row--bundle .table__cell {
      border: none; }
  .table__row:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: rgba(150, 150, 150, 0.15) !important; }
  .table__row.selected {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: #00bf6c !important; }
    .table__row.selected .table__cell {
      color: white; }

.table__heading {
  color: #ffffff;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
  flex: 100 0 auto;
  padding: 9.5px 5px 11.5px; }
  .table__heading--centered {
    text-align: center;
    align-items: center; }
  .table__heading--large {
    width: 180px;
    flex: 180 0 auto; }

.table__cell {
  font-size: 14px;
  line-height: 19px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
  height: 40px;
  flex: 100 0 auto;
  padding: 8.5px 5px 10.5px; }
  .table__cell--centered {
    text-align: center;
    align-items: center; }

.table__body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25); }
  .table__body--products {
    height: 235px;
    max-height: 235px;
    overflow: auto; }
    @media screen and (max-height: 700px) {
      .table__body--products {
        height: 30vh; } }
  .table__body--search {
    box-shadow: none;
    height: 200px;
    max-height: 200px;
    overflow: auto;
    border: solid 1px rgba(128, 128, 128, 0.4);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }

.table__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0 10px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25); }
  .table__footer--dashboard {
    padding: 0;
    border-top: 1px solid rgba(180, 180, 180, 0.5);
    display: flex;
    flex-direction: row;
    justify-content: space-between; }

.table__empty {
  margin-top: -20px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%; }

.table__section--left, .table__section--right {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.table__section--clear {
  padding: 13px 20px 14px 0;
  margin-left: 27px;
  border-right: 1px solid rgba(180, 180, 180, 0.5); }

.table__section--park {
  position: relative;
  padding: 13px 20px 14px 0;
  margin-left: 15px; }

.table__section--discount {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .table__section--discount .label {
    margin-right: 5px; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.quantity {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 300;
  font-size: 16px;
  cursor: pointer; }
  .quantity__value {
    padding: 0 10px;
    width: 122px;
    text-align: center; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.quick-products {
  margin-top: 15px;
  overflow: auto;
  min-height: 300px; }
  .quick-products .nav-tabs {
    min-width: 825px; }
    @media screen and (max-width: 1200px) {
      .quick-products .nav-tabs {
        min-width: 775px; } }
  .quick-products__tab {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 230px;
    min-width: 825px;
    overflow: auto; }
    @media screen and (max-width: 1200px) {
      .quick-products__tab {
        min-width: 775px; } }
  .quick-products--sidebar {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; }
    .quick-products--sidebar .quick-product {
      position: relative;
      width: 114px;
      height: 114px;
      border-radius: 18px;
      border: solid 2px #dfe4ea;
      background-image: linear-gradient(to bottom, #ffffff, #f1f2f6);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 25px 25px 0;
      /* &:nth-child(3) {
                margin-right: 0;
            }*/ }
  .quick-products__product-search {
    /* ALT
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px dashed #2684FF;
    */
    position: absolute;
    float: right;
    width: 381px;
    right: 0px;
    padding: 7px;
    background-color: #e9ebef;
    display: flex;
    justify-content: flex-end; }
    @media screen and (max-width: 1336px) {
      .quick-products__product-search {
        width: 120px;
        height: 0px;
        padding: 0px;
        background-color: none; } }
  .quick-products .search--box {
    color: black; }
    @media screen and (max-width: 1336px) {
      .quick-products .search--box {
        display: none; } }
  .quick-products__buttons {
    display: flex; }
  .quick-products .nav-tabs {
    color: black; }
  .quick-products .icon--box {
    margin-left: 10px;
    margin-bottom: -38px; }

.quick-product {
  position: relative;
  width: 114px;
  height: 100px;
  border-radius: 18px;
  border: solid 2px #dfe4ea;
  background-image: linear-gradient(to bottom, #ffffff, #f1f2f6);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 22px 15px 0;
  /*  &:nth-child(6) {
        margin-right: 0;
    }*/ }
  .quick-product__badge {
    margin-top: 12px;
    width: 30px;
    height: 30px;
    background-color: #0069b3;
    border-radius: 50%;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .quick-product__badge--folder {
      background-color: #ffc931; }
    .quick-product__badge--variation {
      background: #f06;
      background-image: linear-gradient(45deg, #fff722, #ff26f9); }
    .quick-product__badge--plus {
      background-color: #dbdee5;
      font-size: 25px !important; }
  .quick-product__label {
    margin-top: 10px;
    padding: 0 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    color: #000000;
    text-align: center; }
  .quick-product__button {
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: -15px; }
    .quick-product__button--delete {
      background-color: #ff433d; }
    .quick-product__button--edit {
      background-color: #00bf6c; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.panel {
  position: relative; }
  .panel--checkout {
    background-color: #ffffff; }
  .panel__section--total {
    height: 135px;
    background-color: #ffffff;
    padding: 10px 25px; }
    @media screen and (max-height: 500px) {
      .panel__section--total {
        height: 22vh; } }
  .panel__section--numpad {
    height: 500px;
    background-color: #e9ebef; }
    @media screen and (max-height: 700px) {
      .panel__section--numpad {
        height: 65vh; } }
  .panel__section--numpad-animation {
    height: 570px;
    background: linear-gradient(270deg, lightgreen, #e9ebef);
    background-size: 400% 400%;
    -webkit-animation: AnimationName 2s ease infinite;
    -moz-animation: AnimationName 2s ease infinite;
    -o-animation: AnimationName 2s ease infinite;
    animation: AnimationName 2s ease infinite; }

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@-o-keyframes AnimationName {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes AnimationName {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }
    @media screen and (max-height: 700px) {
      .panel__section--numpad-animation {
        height: 78vh; } }
  .panel--sidebar {
    background-color: #ffffff;
    width: 472px;
    height: 100%;
    padding: 0 22.5px 0 24.5px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    overflow-y: auto; }
  .panel--signSidebar {
    background-color: #ffffff;
    width: 472px;
    height: 100%;
    padding: 0 22.5px 0 24.5px;
    margin-right: 0px;
    z-index: 2;
    position: absolute;
    top: 0px;
    bottom: 0px;
    transition: -webkit-transform 10.3s ease-out 0s;
    will-change: transform;
    overflow: hidden;
    background: white;
    right: 0px;
    transform: translateX(0%);
    box-shadow: rgba(0, 0, 0, 0.15) -2px 2px 4px;
    box-shadow: rgba(0, 0, 0, 0.15) -2px 2px 4px; }
  .panel--form {
    background-color: #ffffff;
    padding: 0 22.5px 0 24.5px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 99;
    overflow-y: auto; }
  .panel__title {
    color: #000000;
    padding: 21px 0;
    font-size: 18px;
    font-weight: 500;
    border-bottom: solid 1px #edeff6; }
  .panel__content {
    padding: 25px 0; }
  .panel__close {
    position: absolute;
    top: 25px;
    right: 25px; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.modal--split {
  background-color: white;
  position: absolute;
  left: 0;
  top: 4px;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25); }

.modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px; }

.modal__title {
  font-size: 18px; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.numpad {
  padding: 7px 7px; }
  .numpad__row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 5px;
    text-align: center; }
    .numpad__row:last-child {
      margin-bottom: 0; }
    .numpad__row--disabled {
      pointer-events: none;
      opacity: 0.5; }
  .numpad__btn {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    background-color: #ffffff;
    margin-right: 5px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer; }
    @media screen and (max-height: 700px) {
      .numpad__btn {
        height: 8.35vh; } }
    .numpad__btn:last-child {
      margin-right: 0; }
    .numpad__btn .icon {
      margin-right: 12px; }
    .numpad__btn--success {
      background-color: #00bf6c;
      color: white; }
    .numpad__btn--primary {
      background-color: #0069b3;
      color: white; }
    .numpad__btn--gray {
      background-color: #b0b7c7;
      color: white; }
    .numpad__btn--double {
      flex: 2; }
    .numpad__btn--disabled {
      pointer-events: none;
      opacity: 0.5; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.separator {
  padding: 10px;
  display: inline-block;
  vertical-align: middle; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.total {
  padding: 0 30px 0 25px; }
  .total__row {
    display: flex;
    justify-content: flex-end; }
    .total__row--inventory {
      justify-content: center;
      padding-top: 30px; }
    .total__row--inventoryLabel {
      justify-content: center;
      padding-top: 10px; }
  .total__separator {
    background-color: #ced6e0;
    height: 1px;
    margin: 13px 0 11px; }
  .total__value {
    margin-left: 5px; }

.drawer {
  width: 229px;
  height: 100%;
  padding: 50px 0;
  background-color: #0069b3; }
  .drawer ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  .drawer__close {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px; }
  .drawer__item {
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out; }
    .drawer__item a {
      width: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 17px 25px; }
    .drawer__item:hover {
      background-color: #0076CA; }
    .drawer__item span {
      font-family: SourceSansPro, sans-serif;
      font-size: 15px;
      line-height: 1.27;
      color: #ffffff; }
    .drawer__item .icon {
      margin-right: 12px; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.popup {
  width: 277px;
  display: block;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: none;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  z-index: 5; }
  .popup__header {
    text-align: center;
    background-color: #0069b3;
    padding: 27px 27px 52px 27px; }
  .popup__close {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: right;
    background-color: #0069b3;
    padding: 10px 20px 0;
    cursor: pointer; }
  .popup__avatar {
    width: 50px;
    height: 50px;
    margin: auto;
    line-height: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: #0069b3; }
  .popup__name {
    color: #ffffff;
    margin-top: 14px;
    line-height: 1; }
  .popup__content {
    padding: 0 0 17px;
    background-color: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
  .popup__row {
    padding: 18px 15px;
    color: #0069b3;
    transition: all 0.3s ease-in-out;
    cursor: pointer; }
    .popup__row:hover {
      background-color: rgba(150, 150, 150, 0.15) !important; }
  .popup__amount {
    margin-top: 20px;
    color: white; }
  .popup__amount-value__blur {
    color: transparent;
    text-shadow: 0 0 15px #fffffe;
    cursor: default;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.print {
  visibility: hidden; }

@media print {
  .print {
    margin: 0;
    padding: 0 0 200px 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 12px;
    font-family: Verdana, Helvetica, sans-serif;
    color: #000;
    line-height: 15px;
    overflow-wrap: break-word; }
  .print__logo img {
    max-width: 100%;
    width: 100%; }
  .print__netlink-logo img {
    max-width: 100%; }
  .print__customer {
    margin: 5px 0 20px 0; }
  .print * {
    overflow-wrap: break-word;
    visibility: visible; } }

/* Color palette */
/* Text styles */
/* Breakpoints */
.product__results {
  max-height: 300px;
  overflow: auto;
  margin-bottom: 25px; }

.product__row {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-top: 1px solid rgba(128, 128, 128, 0.07);
  transition: all 0.2s ease-in-out; }
  .product__row:hover {
    transition: all 0.3s ease-in-out;
    background-color: rgba(0, 105, 179, 0.05); }
  .product__row--remote {
    background-color: rgba(128, 128, 128, 0.2);
    font-style: italic;
    color: #666; }

.product__empty {
  margin-top: 20px;
  color: #666; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.reports {
  margin-top: -20px; }
  .reports__list {
    margin-left: -15px; }
    .reports__list .list-group-item {
      cursor: pointer; }
  .reports__toolbar {
    display: flex;
    flex-direction: row; }
  .reports__content {
    margin-top: 15px;
    padding: 0 20px; }
  .reports__lines {
    padding: 20px 10px; }
  .reports__line {
    padding: 5px 10px; }
  .reports__line-row {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    width: 150px;
    justify-content: space-between; }
  .reports__title {
    padding: 5px 10px; }
  .reports__provider {
    padding: 5px 10px; }
  .reports__station {
    padding: 5px 10px; }
    .reports__station span {
      margin-right: 5px; }
  .reports__user {
    padding: 5px 10px; }
    .reports__user span {
      margin-right: 5px; }
  .reports__date {
    padding: 5px 10px; }
    .reports__date span {
      margin-right: 5px; }
  .reports__print {
    margin-top: 20px; }

.report__lines {
  max-height: 243px;
  overflow: auto; }

@media print {
  .reports__data {
    background-color: white;
    margin: 0;
    padding: 0 0 200px 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 12px;
    font-family: Verdana, Helvetica, sans-serif;
    color: #000;
    line-height: 15px;
    overflow-wrap: break-word;
    height: 100%; }
    .reports__data .table__header {
      display: none;
      height: 0;
      padding: 0; }
    .reports__data .table__row {
      padding: 0 5px;
      background: transparent; }
    .reports__data .table__body {
      box-shadow: none;
      background: transparent;
      padding: 0; }
    .reports__data .table__footer {
      padding: 0 5px;
      background: transparent;
      box-shadow: none;
      border-top: 1px dashed #000; }
    .reports__data .reports__lines {
      padding: 5px; }
  .reports__title {
    border-bottom: 1px dashed #000; }
  .reports__data * {
    overflow-wrap: break-word;
    visibility: visible; } }

/* Color palette */
/* Text styles */
/* Breakpoints */
.split__title {
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 1px solid #edeff6; }

.split__body {
  max-width: 455px;
  margin: 60px auto 0; }

.split__row {
  display: flex;
  align-items: center;
  margin: 30px 0; }

.split__label {
  display: flex;
  align-items: center;
  margin-right: 12px; }
  .split__label .icon {
    margin-right: 10px;
    width: 25px;
    height: 25px; }

.split__customer {
  cursor: pointer;
  color: #0069b3;
  margin-left: 30px;
  display: inline-block;
  border-bottom: 1px dashed; }

.split__remaining {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 84px 0 40px 0;
  color: #0069b3; }
  .split__remaining--danger {
    color: #ff433d; }

.split__remainingValue {
  margin-left: 5px; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.tabs {
  margin-top: 15px; }
  .tabs__content {
    padding: 30px 0; }
    .tabs__content--empty {
      padding: 30px; }
  .tabs .nav-tabs .nav-link {
    flex: 1;
    font-weight: normal;
    max-width: 250px; }
  .tabs .nav-tabs {
    border-bottom: 2px solid #0069b3; }

.text {
  display: inline-block;
  color: #000000; }
  .text--larger, .popup__amount-value {
    font-size: 28px;
    line-height: 34px; }
  .text--large, .notification-table__empty, .table__empty, .total__label, .total__value, .reports__title {
    font-size: 22px;
    line-height: 29px; }
  .text--modalTitle, .split__remainingLabel, .split__remainingValue {
    font-size: 18px;
    line-height: 22px; }
  .text--small, .form__row .form-label, .form__label--sidebar, .form__error, .editable__currency, .popup__amount-label, .tabs .nav-tabs .nav-link, .user__name {
    font-size: 14px;
    line-height: 17px; }
  .text--smaller, .notification-table__heading--admin, .notification-table__cell--admin, .notification-table__cell--modal, .quick-product__badge {
    font-size: 12px;
    line-height: 14px; }
  .text--light, .notification-table__empty, .table__empty, .user__name {
    font-weight: 300; }
  .text--medium, .quick-product__badge, .total__value {
    font-weight: 500; }
  .text--bold, .popup__amount-value, .split__remainingValue {
    font-weight: 700; }
  .text--gray, .editable__currency {
    color: #9196a3; }
  .text--brownish-gray {
    color: #707070; }
  .text--danger, .form__error {
    color: #ff433d; }
  .text__button {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    color: black;
    opacity: 1; }
    .text__button:hover {
      color: #0069b3 !important; }
    .text__button--individualParked {
      color: gray;
      padding: 0 2px 0 15px; }
    .text__button--skroutzParked {
      background-color: #f68b24;
      border-radius: 5px;
      color: white;
      margin: 0 2px 0 15px;
      padding: 2px; }
      .text__button--skroutzParked:hover {
        background-color: orange;
        color: white !important; }
    .text__button--selected {
      color: #0069b3; }
    .text__button--disabled {
      opacity: 0.2;
      pointer-events: none; }
    .text__button--parked {
      padding: 0 2px 0 15px; }
    .text__button--options {
      padding: 0 5px 0 15px; }
    .text__button--delete {
      border-radius: 50%;
      width: 13px;
      height: 13px;
      display: inline-flex;
      align-items: center;
      justify-content: center; }
  .text__panel {
    position: absolute;
    background-color: #f9f9f9;
    bottom: 55px;
    left: 0;
    right: 0;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    display: flex;
    display: -webkit-flex;
    display: flex;
    border: 1px solid #ccc;
    flex-wrap: wrap; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.title {
  font-weight: bold;
  padding: 0 0 22px 0;
  margin: 0 5px 38px;
  border-bottom: 1px solid lightGrey;
  color: orange; }
  .title--login {
    font-weight: normal;
    padding: 0;
    border: none;
    margin-top: 0;
    margin-bottom: 33px;
    font-size: 22px;
    color: #000000; }

/* Color palette */
/* Text styles */
/* Breakpoints */
.user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer; }
  .user__name {
    margin: 0 14px; }
