@import "../variables";

.product {
  &__results {
    max-height: 300px;
    overflow: auto;
    margin-bottom: 25px;
  }

  &__row {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    border-top: 1px solid rgba(gray, 0.07);
    transition: all 0.2s ease-in-out;

    &:hover {
      transition: all 0.3s ease-in-out;
      background-color: rgba($brand-color, 0.05);
      //    background-color: #d6e3ff;
    }

    &--remote {
      background-color: rgba(gray, 0.2);
      //background-color: #f9f9f9;
      font-style: italic;
      color: #666;
    }
  }

  &__empty {
    margin-top: 20px;
    color: #666;
  }
}
