@import '../variables';

.notification-table {
  &--admin {
    position: relative;
    height: 100%;
  }

  &--vat {
    margin: auto;
    max-width: 520px;
  }

  &--card {
    overflow: auto;
    max-height: 175px;
  }

  &__wrapper {
    &--details {
      background-color: $white;
      box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25);

      .col:first-child {
        border-right: 1px solid rgba(98, 125, 183, 0.25);
      }
    }
  }

  &__amount {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
      margin-right: 10px;
    }
  }

  &__toolbar {
    background-color: $table-header-bg-admin;
    padding: 10px;
    height: auto;
    display: flex;
    justify-content: flex-end;

    input {
      max-width: 350px;
    }

    &--filters {
      justify-content: space-between;
    }
  }

  &__cardsDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
    background-color: $brand-color;
    height: 40px;
    background-color: #f7f8f9;
    padding: 12px 10px;
    height: auto;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
    background-color: $brand-color;
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &--admin {
      background-color: $table-header-bg-admin;
      padding: 22px 10px;
      height: auto;
      align-items: flex-start;
    }

    &--light {
      background-color: $body-bg;
      padding: 12px 10px;
      height: auto;
      //border-bottom: 1px solid $light-periwinkle;

      &-fill {
        box-shadow: 0 1px 6px 0 rgba(98, 125, 183, 0.25);
      }
    }

    &--details {
      background-color: white;
      padding: 22px 10px;
      height: auto;
    }

    &--modal {
      background-color: $table-header-bg-admin;
      padding: 3px 10px;
      height: auto;
    }

    &--modal-alt {
      background-color: $table-header-bg-admin;
      padding: 3px 10px;
      height: auto;
      border-radius: 0;
      border-top: 1px solid #9196a3;
      border-bottom: 1px solid #9196a3;
    }
  }

  &__username {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    // margin-top:-8px;
    margin: 4px 0;

    .avatar {
      margin-right: 10px;
    }
  }

  &__row {
    background-color: $white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 18px;
    padding: 0 32px;

    &:last-child {
      .table__cell {
        border-bottom: none;
      }
    }

    &--card {
      padding: 0;
    }

    &--stripped {
      &:nth-child(odd) {
        background-color: rgba($brand-color, 0);
        border-bottom: none !important;
      }
      &:nth-child(even) {
        background-color: rgba($brand-color, 0.05);
        border-bottom: none !important;
      }
    }

    &--admin {
      padding: 0 10px;

    }

    &:hover {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background-color: rgba(gray, 0.2) !important;
      //background-color: $pale-grey-three !important;
    }

    &.selected {
      background-color: $shamrock-green !important;

      .table__cell {
        color: $white !important;
      }
    }

    &.warning {
      background-color: $macaroni-and-cheese !important;

      .table__cell {
        color: $white !important;
      }
    }

    &.skroutz {
      background-color: $skroutz !important;

      .table__cell {
        color: $white !important;
      }
    }

    &.danger {
      background-color: $danger !important;

      .table__cell {
        color: $white !important;
      }
    }

    &.selected-blue {
      background-color: $brand-color !important;

      .table__cell {
        color: $white !important;
      }
    }
  }

  &__heading {
    color: $white;
    font-size: 13px;
    line-height: 15px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100px;
    flex: 100 0 auto;
    padding: 9.5px 5px 11.5px;

    .custom-control {
      margin-top: -5px;
    }

    &--centered {
      text-align: center;
      align-items: center;
    }

    &--admin {
      @extend .text--smaller;
      color: $text-primary;
      //padding: 9.5px 10px 11.5px;
      padding: 12px 10px 12px;
    }

    &--date {
      color: $text-primary;

      .react-datepicker-wrapper:before {
        top: 18px;
      }

      input {
        height: 38px;
        padding: 8px 25px 8px 8px;
        margin-top: 8px;
      }
    }

    &--vat {
      font-size: 16px;
      align-items: center;
    }
  }

  &__cell {
    font-size: 14px;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100px;
    height: 40px;
    flex: 100 0 auto;
    padding: 8.5px 5px 10.5px;
    //border-bottom: solid 1px rgba($table-border, 0.2);
    border-bottom: none !important;

    .custom-control {
      margin-top: -5px;
    }

    &--centered {
      text-align: center;
      align-items: center;
    }

    &--align-right {
      text-align: right;
      align-items: flex-end;
    }

    &--card {
      height: auto;
      padding: 3px 5px 4px;
    }

    &--admin {
      @extend .text--smaller;
      color: $text-primary;
      padding: 12px 10px 12px;
    }

    &--modal {
      @extend .text--smaller;
      color: $text-primary;
      padding: 5px 10px 5px;
      align-items: center;
      justify-content: center;

      .form-control {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &--modalFooter {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &--name {
      padding: 0 10px 0;
      display: flex;
      justify-content: center;
    }

    &--start {
      width: 100px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    &--actions {
      width: 100px;
      flex: 0 0 100px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 0;

      .btn--withIcon {
        padding: 0 5px;
        min-width: 120px;
        height: 40px;
      }
    }

    &--small {
      width: 10px;
      flex: 0 0 10px;
      margin-right: 15px;
    }

    &--number {
      width: 75px;
      flex: 0 0 75px;
      margin-right: 15px;
    }

    &--amount {
      width: 125px;
      flex: 0 0 115px;
      margin-right: 15px;
    }

    &--large {
      width: 175px;
      flex: 0 0 175 auto;
    }

    &--fulWidth {
      flex: 1;
    }

    &--noborder {
      border: none;
    }
  }

  &__filters-wrapper {
    display: flex;
  }

  &__filter {
    position: relative;
    .icon {
      position: absolute;
      right: 8px;
      top: 22px;
    }

    &--calendar {
      max-width: 175px;
      margin-right: 15px;
    }

    &--select {
      margin-top: 8px;
      width: 100%;
    }
  }

  &__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 0;
    background-color: $white;
    box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25);
    overflow: auto;
    min-height: 150px;

    &--products {
      height: 295px;
      max-height: 295px;
      overflow: auto;
    }

    &--details {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0 10px;
    background-color: $white;
    box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25);
  }

  &__input {
    width: 100%;
    outline: none;
    margin-top: 7px;
    border-radius: 5px;
    background-color: $white;
    height: 38px;
    border: solid 1px $bluey-grey;
    transition: all 0.3s ease-in-out;
    padding: 8px 25px 8px 8px;

    &:focus {
      box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25);
      border: solid 1px $brand-color;
    }
  }

  &__pagination {
    .sr-only {
      display: none;
    }

    .page-link {
      text-decoration: none;
      box-shadow: none;
      outline: none;
    }
  }

  &__empty {
    @extend .text--large;
    @extend .text--light;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__loading {
    color: $brand-color;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    //background-color: rgba(0,0,0,0.2);
  }

  &__excel_loading {
    color: $excel-green;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    //background-color: rgba(0,0,0,0.2);
  }

  &__excel_loading_text {
    color: $cool-grey;
    margin-left: 15px;
  }

  &__excel_loading_complete {
    color: $black;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    //background-color: rgba(0,0,0,0.2);
  }
}

.extra_row {
  padding: 0 20px 22px;
  background-color: rgba(176, 183, 199, 0.08);
  height: auto;
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
}

.notification-box {
  
    background-color: $white;
    box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25);

    .col:first-child {
      border-right: 1px solid rgba(98, 125, 183, 0.25);
    }
  
}

.notify {
  background-color: rgb(255, 255, 255);
  //border-radius: 3px;
  padding: 0px;
  color: black;
}
