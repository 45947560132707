@import '../variables';

.btn {
  position: relative;
  height: 50px;
  padding: 14px 40px;
  min-width: 159px;
  max-width: 100%;
  font-size: 16px;
  color: $white;
  border-radius: 5px;
  border: 1px solid transparent;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  outline: none;

  &:disabled {
    opacity: 0.3;
  }

  &--link {
    border: 2px solid transparent !important;
    background-color: transparent !important;
    color: $brand-color;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    span {
      vertical-align: middle;
      display: inline-block;
    }

    &.btn--primary {
      color: $brand-color;

      &:hover {
        color: $brand-color-light !important;
      }
    }
  }

  &--primary {
    border: 2px solid $brand-color;
    background-color: $brand-color;
    color: $white;

    &:hover {
      background-color: $brand-color-hover;
    }

    &.btn--outline {
      color: $brand-color;
      background-color: transparent;

      &:hover {
        background-color: $brand-color;
        color: $white;
      }

      &.active {
        background-color: $brand-color;
        color: $white;
      }

      &.selected {
        background-color: #00bf6c;
        border: 2px solid #00bf6c;
        color: $white;
      }
    }

    &.btn--filter {
      color: $brand-color;
      background-color: transparent;

      min-width: 55px;
      min-height: 40px;
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 10px;

      &:hover {
        background-color: $brand-color;
        color: $white;
      }

      &.active {
        background-color: $brand-color;
        color: $white;
      }

      &.selected {
        background-color: #00bf6c;
        border: 2px solid #00bf6c;
        color: $white;
      }
    }

    &.btn--cents {
      color: $brand-color;
      background-color: transparent;
      min-width: 55px;
      min-height: 55px;
      padding: 0px;

      &:hover {
        background-color: $brand-color;
        color: $white;
      }

      &.active {
        background-color: $brand-color;
        color: $white;
      }

      &.selected {
        background-color: #00bf6c;
        border: 2px solid #00bf6c;
        color: $white;
      }
    }
  }

  &--small {
    height: 40px;

    margin-left: 10px;

    padding: 9px 0;
    min-width: 100px;
    border: 2px solid $brand-color;
    background-color: $brand-color;
    color: $white;

    &:hover {
      background-color: $brand-color-hover;
    }

    &.btn--outline {
      color: $brand-color;
      background-color: $white;

      &:hover {
        background-color: $brand-color;
        color: $white;
      }

      &.active {
        background-color: $brand-color;
        color: $white;
      }
    }
  }

  &--danger {
    background-color: $danger;
    color: $white;

    &:hover {
      background-color: $danger-hover;
    }
  }

  &--white {
    border: 2px solid $white;
    background-color: transparent;
    color: $white;

    &:hover {
      background-color: $pale-grey-two;
      color: $brand-color;
    }
  }

  &--success {
    background-color: $success;
    color: $white;

    &:hover {
      background-color: $success-hover;
    }
  }

  &--warning {
    background-color: $warning;
    color: $white;

    &:hover {
      background-color: $warning-hover;
    }
  }

  &--round {
    height: 45px;
    border-radius: 30px;
    font-size: 14px;
  }

  &--fullwidth {
    width: 100%;
  }

  &--login {
    background-color: transparent;
    display: block;
    height: 60px;
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    border-radius: 50%;
    margin-left: 165px;
    padding: 0px;
    border: 2px solid $white;
    background-color: transparent;
    color: white;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.btninpt {
  position: relative;
  height: 50px;
  padding: 14px 0px;
  width: 90px;
  text-align: center;
  height: 45px;
  font-size: 16px;
  color: $white;
  //border-radius: 5px;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  outline: none;

  &:disabled {
    opacity: 0.3;
  }

  &--primary {
    border: 2px solid $brand-color;
    background-color: transparent;
    color: $brand-color;

    &.btn--outline {
      color: $brand-color;
      background-color: transparent;
    }
  }

  &--selected {
    border: 2px solid #00bf6c;
    color: #00bf6c;
  }
}
