@import "../variables";

.table {
  &--products {
    overflow: auto;

    .table__header,
    .table__body,
    .table__footer {
      min-width: 825px;

      @media screen and (max-width: $lg) {
        min-width: 775px;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
    background-color: $brand-color;
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__row {
    background-color: $white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 18px;
    padding: 0 32px;
    flex: 0 0 40px;
    min-height: 50px;

    &:last-child {
      .table__cell {
        border-bottom: none;
      }
    }

    &--stripped {
      &:nth-child(odd) {
        background-color: white;
      }
      &:nth-child(even) {
        background-color: rgba($brand-color, 0.05);
      }

      .table__cell {
        border: none;
      }
    }

    &--bundle {
      background-color: $white;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 18px;
      padding: 0 32px;
      flex: 0 0 40px;
      min-height: 50px;

      &--0 {
        background-color: rgba($success, 0.15) !important;
        &:hover {
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          background-color: rgba($success, 0.25) !important;
        }
      }

      &--1 {
        background-color: rgba($warning, 0.15) !important;
        &:hover {
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          background-color: rgba($warning, 0.25) !important;
        }
      }

      &--2 {
        background-color: rgba($danger, 0.15) !important;
        &:hover {
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          background-color: rgba($danger, 0.25) !important;
        }
      }

      &--3 {
        background-color: rgba($brand-color, 0.15) !important;
        &:hover {
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          //background-color: $pale-grey-three !important;
          background-color: rgba($brand-color, 0.25) !important;
        }
      }

      .table__cell {
        border: none;
      }
    }

    &:hover {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      //background-color: $pale-grey-three !important;
      background-color: rgba(150, 150, 150, 0.15) !important;
    }

    &.selected {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background-color: $success !important;

      .table__cell {
        color: white;
      }
    }
  }

  &__heading {
    color: $white;
    font-size: 13px;
    line-height: 15px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100px;
    flex: 100 0 auto;
    padding: 9.5px 5px 11.5px;

    &--centered {
      text-align: center;
      align-items: center;
    }

    &--large {
      width: 180px;
      flex: 180 0 auto;
    }
  }

  &__cell {
    font-size: 14px;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100px;
    height: 40px;
    flex: 100 0 auto;
    padding: 8.5px 5px 10.5px;
    //border-bottom: 1px solid $table-border;

    &--centered {
      text-align: center;
      align-items: center;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 0;
    background-color: $white;
    box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25);

    &--products {
      height: 235px;
      max-height: 235px;
      overflow: auto;

      @media screen and (max-height: 700px) {
        height: 30vh;
      }
    }

    &--search {
      box-shadow: none;
      height: 200px;
      max-height: 200px;
      overflow: auto;
      border: solid 1px rgba(gray, 0.4);
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0 10px;
    background-color: $white;
    box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25);

    &--dashboard {
      padding: 0;
      //border-top: 1px solid $pale-grey-three;
      border-top:1px solid rgba(180, 180, 180, .5);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__empty {
    @extend .text--large;
    @extend .text--light;
    margin-top: -20px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__section {
    &--left,
    &--right {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    &--clear {
      padding: 13px 20px 14px 0;
      margin-left: 27px;
      border-right:1px solid rgba(180, 180, 180, .5);
    }

    &--park {
      position: relative;
      padding: 13px 20px 14px 0;
      margin-left: 15px;
    }

    &--discount {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .label {
        margin-right: 5px;
      }
    }
  }
}
