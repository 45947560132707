@import '../variables';

.popup {
  width: 277px;
  display: block;

  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: none;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  z-index: 5;

  &__header {
    //margin-bottom: 25px;
    text-align: center;
    background-color: $brand-color;
    padding: 27px 27px 52px 27px;
  }

  &__close {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: right;
    background-color: $brand-color;
    padding: 10px 20px 0;
    cursor: pointer;
  }

  &__avatar {
    width: 50px;
    height: 50px;
    margin: auto;
    line-height: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    color: $brand-color;
  }

  &__name {
    color: $white;
    margin-top: 14px;
    line-height: 1;
  }

  &__content {
    padding: 0 0 17px;
    background-color: $white;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__row {
    padding: 18px 15px;
    color: $brand-color;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: rgba(150, 150, 150, 0.15) !important;
    }
  }

  &__amount {
    margin-top: 20px;
    color: white;
  }

  &__amount-label {
    @extend .text--small;
  }

  &__amount-value {
    @extend .text--larger, .text--bold;

    &__blur {
      color: transparent;
      text-shadow: 0 0 15px rgba(255, 255, 254, 1);
      cursor: default;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }
  }
}
