@import '../variables';

.modal {
   &--split {
       background-color: white;
       position: absolute;
       left: 0;
       top: 4px;
       right: 0;
       bottom: 0;
       border-radius: 5px;
       box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25);
   }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
    }

    &__title {
        font-size: 18px;
    }
}
