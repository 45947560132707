@import '../variables';

.quantity {
    -webkit-tap-highlight-color: transparent;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 300;
    font-size: 16px;
    cursor: pointer;

    &__value {
        padding: 0 10px;
        width: 122px;
        text-align: center;
    }
}